import React from "react";
import { createRoot } from "react-dom/client";
import { configure as configureMobX } from "mobx";
import initialzeAmplify from "./services/AmplifyService";
import App from "./app";
import "./styles/index.scss";

// Configure MobX
configureMobX({ enforceActions: "never" });

// Initialize Amplify FIRST before rendering the app
initialzeAmplify();

// React 18 way of rendering
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
