import { useNavigate, Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { EditShippingDatesModal as EditShippingDatesModalBody, Input, Select, Spinner } from "../-common";
import useModal from "../../hooks/useModal";
import useQueryParams from "../../hooks/useQueryParams";
import ClassesStore from "../../stores/ClassesStore";
import UserStore from "../../stores/UserStore";
import AddModal from "./AddClassModal";
import ClassDetails from "./ClassDetails";
import "./Classes.scss";
import ClassRow from "./ClassRow";


const DAY_ORDER = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const CURRICULUM_ORDER = { "Sound Beginnings": 0, "Let's Play Music": 1, Presto: 2 };

const sortByCurriculum = (a, b) => (CURRICULUM_ORDER[a] < CURRICULUM_ORDER[b] ? -1 : 1);

const sortClassesByCurriculumCourseDayAndTime = (a, b) => {
  const {
    curriculum: { title: aCurriculum } = {},
    order: aCourseOrder,
    sessionTime: aSessionTime,
    rawSessionTime: aRawSessionTime
  } = a?.course || {};
  const {
    curriculum: { title: bCurriculum } = {},
    order: bCourseOrder,
    sessionTime: bSessionTime,
    rawSessionTime: bRawSessionTime
  } = b?.course || {};
  const aDaySort = DAY_ORDER?.indexOf(aSessionTime?.slice(0, 3));
  const bDaySort = DAY_ORDER?.indexOf(bSessionTime?.slice(0, 3));

  if (aCurriculum !== bCurriculum) return sortByCurriculum(aCurriculum, bCurriculum);
  if (aCourseOrder !== bCourseOrder) return aCourseOrder > bCourseOrder ? 1 : -1;
  if (aDaySort !== bDaySort) return aDaySort > bDaySort ? 1 : -1;
  return aRawSessionTime > bRawSessionTime ? 1 : -1;
};

const Classes = observer(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [downloading, setDownloading] = useState(false);

  const { loading, currentClasses, allClasses, classStatusOptions } = ClassesStore || {};
  const { teacherProfile } = UserStore?.user || {};
  const { shippingDates } = teacherProfile || {};
  const { winter, fall, summer } = shippingDates || {};
  const hasConfiguredShippingDates = !!winter && !!fall && !!summer;

  const { status: queryParamStatus } = useQueryParams();
  const [status, setStatus] = useState(classStatusOptions?.[0] || "Upcoming");
  const updateStatus = selectedStatus => {
    navigate(pathname + "?status=" + encodeURIComponent(selectedStatus));
    if (status !== selectedStatus) setStatus(selectedStatus);
  };
  useEffect(() => {
    if (!status) setStatus(classStatusOptions?.[0]);
  }, [status, classStatusOptions]);
  useEffect(() => {
    if (queryParamStatus && queryParamStatus !== status) setStatus(queryParamStatus);
  }, [queryParamStatus, status]);

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target?.value);

  const downloadFullRoster = async () => {
    setDownloading(true);
    await ClassesStore.downloadFullCurrentRoster();
    setDownloading(false);
  };

  const { Modal: AddClassModal, openModal: openAddClassModal } = useModal(
    AddModal,
    {},
    { portalElementId: "add-class-modal", big: true }
  );

  const { Modal: EditShippingDatesModal, openModal: openEditShippingDatesModal } = useModal(
    EditShippingDatesModalBody,
    { teacherInfo: teacherProfile },
    { portalElementId: "edit-shipping-dates-modal" }
  );

  const relevantClasses = allClasses
    ?.filter(c => {
      if (status === "All") return true;
      if (status === "Upcoming") return c?.status === "ready";
      return c?.status?.toLowerCase() === status?.toLowerCase();
    })
    ?.sort(sortClassesByCurriculumCourseDayAndTime);
  // ?.sort((a, b) => (a?.startDate > b?.startDate ? -1 : 1));
  const searchedClasses = relevantClasses?.filter(c => {
    if (!searchText) return true;

    const textToMatch = searchText?.toLowerCase();
    const matchesName = c?.name?.toLowerCase()?.includes(textToMatch);
    const matchesPeriod = c?.period?.toLowerCase()?.includes(textToMatch);
    const matchesSessionTime = c?.sessionTime?.toLowerCase()?.includes(textToMatch);
    return matchesName || matchesPeriod || matchesSessionTime;
  });
  const classRows = searchedClasses?.map(classObject => (
    <ClassRow {...{ ...classObject }} key={classObject?.id || classObject?.classId} />
  ));

  if (loading) {
    return (
      <div className="classes classes-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const shippingDatesAction = hasConfiguredShippingDates ? null : (
    <div className="action-row">
      <div className="action" onClick={openEditShippingDatesModal}>
        <span>Manage Shipping Dates</span>
      </div>
    </div>
  );

  const downloadButtonContent = downloading ? (
    <div className="action-loading-container">
      <Spinner action />
    </div>
  ) : (
    <span>Download Full Roster</span>
  );

  const tooltip = !!hasConfiguredShippingDates ? null : (
    <Tooltip id="add-classes-tooltip" place="top" effect="solid" />
  );
  return (
    <div className="classes">
      <div className="title-row">
        <div className="title">Classes</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Current classes</div>
            <div className="info-value">{currentClasses?.length}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Total classes</div>
            <div className="info-value">{allClasses?.length}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div
              className={`action${hasConfiguredShippingDates ? "" : " disabled"}`}
              onClick={openAddClassModal}
            >
              <span data-tooltip-id="add-classes-tooltip">
                Add Class
              </span>
            </div>
            {tooltip}
          </div>
          {shippingDatesAction}
          <div className="action-row">
            <div className="action" onClick={downloadFullRoster}>
              {downloadButtonContent}
            </div>
          </div>
        </div>
      </div>
      <div className="header">Classes</div>
      <div className="filter-search-row">
        <div className="filter-classes">
          <span className="filter-classes-label">Status:</span>
          <Select options={classStatusOptions} value={status} onChange={updateStatus} />
        </div>
        <div className="search-classes">
          <Input icon={FiSearch} placeholder="Search classes..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      <div className="table-wrapper classes-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Schedule</th>
              <th>Registrations</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{classRows}</tbody>
        </table>
      </div>

      <EditShippingDatesModal />
      <AddClassModal />
    </div>
  );
});

const ClassDetailsWrapper = () => {
  const { classId } = useParams();
  return <ClassDetails classId={classId} />;
};

export default function ClassesRouter() {
  return (
    <Routes>
      <Route path="/" element={<Classes />} />
      <Route path="/:classId" element={<ClassDetailsWrapper />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
