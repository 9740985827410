import React, { useState } from "react";
import { observer } from "mobx-react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import UserStore from "../../stores/UserStore";
import useModal from "../../hooks/useModal";
import { Spinner, Input } from "../-common";
import ChildDetails from "./ChildDetails";
import AddChildModalBody from "./AddChildModal";
import ChildRow from "./ChildRow";
import "./Children.scss";

const ChildDetailsWrapper = () => {
  const { lpmId } = useParams();
  return <ChildDetails lpmId={lpmId} />;
};

const ChildrenList = observer(() => {
  const { loading: loadingUser, loadingChildren, children } = UserStore || {};

  const { Modal: AddChildModal, openModal: openAddChildModal } = useModal(
    AddChildModalBody,
    {},
    { portalElementId: "add-student-modal" }
  );

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target?.value);

  if (loadingUser || loadingChildren) {
    return (
      <div className="children children-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const relevantChildren = children?.filter(s => {
    if (!searchText) return true;

    const textToMatch = searchText?.toLowerCase();
    const matchesStudentName = `${s?.firstName} ${s?.lastName}`.toLowerCase()?.includes(textToMatch);
    const matchesParentName = `${s?.parent?.firstName} ${s?.parent?.lastName}`.toLowerCase()?.includes(textToMatch);
    return matchesStudentName || matchesParentName;
  });
  const studentRows = relevantChildren?.map(s => <ChildRow child={s} key={s?.id} />);

  return (
    <div className="children">
      <div className="title-row">
        <div className="title">My Children</div>
      </div>
      <div className="upper">
        {/* <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Some Stat</div>
            <div className="info-value">value</div>
          </div>
          <div className="info-row">
            <div className="info-title">Some Stat</div>
            <div className="info-value">value</div>
          </div>
        </div> */}
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action">
              <span onClick={openAddChildModal}>Add Child</span>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-search-row">
        <div className="header">Children</div>
        <div className="search-children">
          <Input icon={FiSearch} placeholder="Search children..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      <div className="table-wrapper children-table">
        <table>
          <thead>
            <tr>
              <th>Child</th>
              <th>Notes</th>
              <th>Most Recent Class</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{studentRows}</tbody>
        </table>
      </div>
      <AddChildModal />
    </div>
  );
});

export default function ChildrenRouter() {
  return (
    <Routes>
      <Route path="/" element={<ChildrenList />} />
      <Route path="/:lpmId" element={<ChildDetailsWrapper />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
