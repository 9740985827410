import React, { useState } from "react";
import { observer } from "mobx-react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { ENV } from "../../config";
import TeachersStore from "../../stores/TeachersStore";
import { Spinner, Input } from "../-common";
import TeacherDetails from "./TeacherDetails";
import TeacherRow from "./TeacherRow";
import "./Teachers.scss";

const Teachers = observer(() => {
  const { loading, teachers } = TeachersStore || {};

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target?.value);

  if (loading) {
    return (
      <div className="teachers teachers-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const enrollmentLink = `https://enrollment${ENV === "dev" ? ".dev" : ""}.letsplaymusicsite.com`;

  const relevantTeachers = teachers?.filter(s => {
    if (!searchText) return true;

    const textToMatch = searchText?.toLowerCase();
    const matchesStudentName = `${s?.firstName} ${s?.lastName}`.toLowerCase()?.includes(textToMatch);
    const matchesParentName = `${s?.parent?.firstName} ${s?.parent?.lastName}`.toLowerCase()?.includes(textToMatch);
    return matchesStudentName || matchesParentName;
  });
  const teacherRows = relevantTeachers?.map(t => <TeacherRow {...t} key={t?.id} />);

  return (
    <div className="teachers">
      <div className="title-row">
        <div className="title">My Teachers</div>
      </div>
      <div className="upper">
        {/* <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Some Stat</div>
            <div className="info-value">value</div>
          </div>
          <div className="info-row">
            <div className="info-title">Some Stat</div>
            <div className="info-value">value</div>
          </div>
        </div> */}
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action">
              <a href={enrollmentLink} rel="noreferrer" target="_blank">
                Enroll with Another Teacher
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-search-row">
        <div className="header">Teachers</div>
        <div className="search-teachers">
          <Input icon={FiSearch} placeholder="Search teachers..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      <div className="table-wrapper teachers-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Currently Teaching</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{teacherRows}</tbody>
        </table>
      </div>
    </div>
  );
});

const TeacherDetailsWrapper = () => {
  const { lpmId } = useParams();
  return <TeacherDetails lpmId={lpmId} />;
};

export default function TeachersRouter() {
  return (
    <Routes>
      <Route path="/" element={<Teachers />} />
      <Route path="/:lpmId" element={<TeacherDetailsWrapper />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
