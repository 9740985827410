import { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import { FiPlus, FiMinus, FiBox } from "react-icons/fi";
import { ENV } from "../../config";
import UserStore from "../../stores/UserStore";
import MaterialsStore from "../../stores/MaterialsStore";
import { Spinner, Select } from "../-common";
import useCardValid from "./useCardValid";
import CheckoutButton from "./CheckoutButton";
import PaymentInfoSection, { NEW_CARD_OPTION_LABEL } from "./PaymentInfoSection";
import classes from "./OrderMaterials.module.scss";

const ProductItem = ({ product, quantities, updateQuantity }) => {
  const { id, name, longDescription: description, price } = product || {};
  const quantity = quantities?.[id];

  const [imageError, setImageError] = useState(false);
  const markImageInvalid = () => setImageError(true);

  const removeQuantity = () => updateQuantity(id, -1);
  const addQuantity = () => updateQuantity(id, 1);

  const image = imageError ? (
    <div className={`${classes.previewImage} ${classes.fallback}`}>
      <FiBox color="var(--medium-gray)" size={40} />
    </div>
  ) : (
    <img
      className={classes.previewImage}
      src={`https://${ENV}-lpm-assets.b-cdn.net/products/${id}`}
      alt="What this product looks like."
      onError={markImageInvalid}
    />
  );

  const priceLabel = <div className={classes.price}>${(price / 100).toFixed(2)}</div>;

  return (
    <div className={classes.materialItem}>
      <div className={classes.materialItemContent}>
        {image}
        <div className={classes.infoContainer}>
          <div className={classes.title}>{name}</div>
          <div className={classes.info}>{description}</div>
          <div style={{ flex: 1 }} />
          {priceLabel}
        </div>
        <div className={classes.quantityContainer}>
          <FiMinus
            className={`${classes.quantityIcon}${!quantity ? " " + classes.disabled : ""}`}
            strokeWidth={4}
            onClick={removeQuantity}
          />
          <div className={classes.quantity}>{quantity || 0}</div>
          <FiPlus className={classes.quantityIcon} strokeWidth={4} onClick={addQuantity} />
        </div>
      </div>
    </div>
  );
};

function OrderMaterials() {
  const navigate = useNavigate();

  const { loading: loadingUser, user, loadingChildren, children, loadingCards, cards } = UserStore || {};
  const { registrations } = user || {};
  const activeClasses = registrations
    ?.filter(r => r?.isClassActive)
    ?.map(r => {
      const child = children?.find(c => c?.id === r?.connectionId);
      return { ...r?.class, child };
    });
  const activeClassIds = activeClasses?.map(c => c?.id);
  const formatClassOption = ({ label }) => {
    const { name: className, child } = activeClasses?.find(c => c?.id === label) || {};
    return `${child?.firstName} - ${className}`;
  };

  const { loading: loadingMaterials, materialsByProductId, products } = MaterialsStore || {};

  const [classIdToReorderFor, setClassIdToReorderFor] = useState();
  const updateClassIdToReorderFor = classId => {
    if (classIdToReorderFor !== classId) setQuantities({});
    setClassIdToReorderFor(classId);
  };
  const courseIdToReorderFor = activeClasses?.find(ac => ac?.id === classIdToReorderFor)?.courseId;

  const [quantities, setQuantities] = useState({});
  const updateQuantity = (productId, quantity) => {
    setQuantities(prev => {
      const newQuantity = Math.max(0, (prev?.[productId] || 0) + quantity);
      if (newQuantity > 0) {
        return { ...prev, [productId]: newQuantity };
      } else {
        const { [productId]: _, ...rest } = prev || {};
        return rest;
      }
    });
  };

  const [selectedCard, setSelectedCard] = useState();
  const [enteringNewCard, setEnteringNewCard] = useState(false);
  const [newCardDetails, setNewCardDetails] = useState({});
  const cardValid = useCardValid({ cardDetails: newCardDetails, setCardDetails: setNewCardDetails });
  const [processing, setProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const updateSelectedCard = selectedCardLabel => {
    if (selectedCardLabel === NEW_CARD_OPTION_LABEL) {
      setSelectedCard(NEW_CARD_OPTION_LABEL);
      setEnteringNewCard(true);
    } else {
      setSelectedCard(cards?.find(c => c?.label === selectedCardLabel));
    }
  };

  const selectionEntries = Object.entries(quantities)?.filter(([_, quantity]) => quantity > 0);
  const total = selectionEntries?.reduce((acc, [pid, quantity]) => {
    const { price } = materialsByProductId?.[pid] || {};
    return acc + price * quantity;
  }, 0);
  const hasProductsToShip = selectionEntries?.length > 0 && total > 0;
  let checkoutEnabled = false;
  if (hasProductsToShip && selectedCard === NEW_CARD_OPTION_LABEL ? cardValid : !!selectedCard) checkoutEnabled = true;
  const completePurchase = async () => {
    if (checkoutEnabled) {
      setProcessing(true);
      let card = selectedCard;
      if (selectedCard === NEW_CARD_OPTION_LABEL && cardValid) {
        card = await UserStore?.createCard(newCardDetails);
        if (!card) {
          setCheckoutError(
            "Looks like we had an issue adding your card. Try refreshing the page, and if you still don't see your card in the list, please contact our office."
          );
          return;
        }
      }

      const classObject = activeClasses?.find(c => c?.id === classIdToReorderFor);
      const { success, error } = await MaterialsStore?.orderReplacementMaterials({
        teacher: classObject?.teacher,
        class: classObject,
        products: selectionEntries,
        card
      });
      if (success) navigate(`/thankyou`);
      else setCheckoutError(error);
      setProcessing(false);
    }
  };

  if (loadingUser || loadingChildren || loadingCards || loadingMaterials) {
    return (
      <div className={`${classes.wrapper} ${classes.loading}`}>
        <Spinner alt={true} />
      </div>
    );
  }

  const relevantProducts = products?.filter(
    p => p?.courseIds?.includes(courseIdToReorderFor) && !p?.name?.toLowerCase()?.includes("shipping")
  );
  const productItems = relevantProducts?.map(product => (
    <ProductItem {...{ product, quantities, updateQuantity }} key={product?.id} />
  ));

  const relevantProductsContent = courseIdToReorderFor ? (
    <div className={classes.resultsContainer}>{productItems}</div>
  ) : null;

  const selectionLines = selectionEntries?.map(([pid, quantity]) => {
    const { name, price } = materialsByProductId?.[pid] || {};
    const label = quantity + "x " + name + " - " + numeral((price * quantity) / 100).format("$0,0.00");
    return (
      <div className={classes.selectionLine} key={name}>
        {label}
      </div>
    );
  });
  const selectionsSection = selectionLines?.length ? (
    <>{selectionLines}</>
  ) : (
    <div className={classes.noSelections}>(no materials selected)</div>
  );

  const actionButtonStyles = enteringNewCard ? null : { marginTop: 48 };
  const actionButtonDisabled = !checkoutEnabled || processing;
  const actionButton = (
    <>
      <CheckoutButton
        style={actionButtonStyles}
        total={total}
        action={completePurchase}
        disabled={actionButtonDisabled}
        processing={processing}
        hasProductsToShip={hasProductsToShip}
      />
      <div className={classes.termsAndConditionsDisclaimer}>
        By completing your purchase, you agree to the following{" "}
        <a
          className={classes.termsAndConditionsLink}
          href="https://www.new.letsplaymusicsite.com/enrollmentdisclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions.
        </a>
      </div>
      <div className={classes.materialsReminder}>
        As a reminder, your materials will be delivered to your teacher who will distribute them to you at the beginning
        of your class.
      </div>
    </>
  );

  const paymentInfoState = { selectedCard, enteringNewCard, newCardDetails, actionButton, checkoutError };
  const paymentInfoActions = { updateSelectedCard, setEnteringNewCard, setNewCardDetails };
  const paymentInfoProps = { ...paymentInfoState, ...paymentInfoActions };

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleRow}>
        <div className={classes.title}>Order Replacement Materials</div>
      </div>
      <div className={classes.content}>
        <div className={classes.productsCol}>
          <div className={classes.selectClassContainer}>
            <Select
              placeholder="Select the class you need materials for"
              options={activeClassIds}
              getOptionLabel={formatClassOption}
              value={classIdToReorderFor}
              onChange={updateClassIdToReorderFor}
            />
          </div>
          {relevantProductsContent}
        </div>
        <div className={classes.selectionsCol}>
          <div className={`${classes.sectionHeader} ${classes.top}`}>Selections</div>
          {selectionsSection}
          <div className={`${classes.sectionHeader}`}>Payment Information</div>
          <PaymentInfoSection {...{ ...paymentInfoProps, hasProductsToShip }} />
        </div>
      </div>
    </div>
  );
}

export default observer(OrderMaterials);
