import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
import { COGNITO_CONFIG, API_CONFIG } from "../config";

function initialize() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: COGNITO_CONFIG.userPoolId,
        userPoolClientId: COGNITO_CONFIG.userPoolWebClientId,
        region: COGNITO_CONFIG.region,
        loginWith: {
          username: true,
          email: true,
          phone: false
        }
      }
    },
    API: {
      REST: {
        [API_CONFIG.endpoints[0].name]: {
          endpoint: API_CONFIG.endpoints[0].endpoint,
          region: COGNITO_CONFIG.region,
          custom_header: async () => {
            try {
              const session = await fetchAuthSession();
              return {
                Authorization: `Bearer ${session.tokens.idToken.toString()}`,
                'Content-Type': 'application/json'
              };
            } catch (error) {
              console.error('Error getting auth session:', error);
              return {
                'Content-Type': 'application/json'
              };
            }
          }
        }
      }
    }
  });
}

export default initialize;
