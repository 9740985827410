import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../-common";
import TeachersStore from "../../../stores/TeachersStore";
import "./TeacherDetails.scss";

function TeacherDetails({ lpmId }) {
  const navigate = useNavigate();
  const { loading, teachers } = TeachersStore || {};

  const teacher = teachers?.find(c => c?.id === lpmId);
  useEffect(() => {
    if (teachers?.length && !teacher) navigate("/teachers");
  }, [teachers, teacher, navigate]);

  if (loading) {
    return (
      <div className="teacher-details teacher-details-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  return (
    <div className="teacher-details">
      <div className="title-row">
        <div className="title">Teacher Details</div>
      </div>
    </div>
  );
}

export default observer(TeacherDetails);
