import { observer } from "mobx-react";
import React from "react";
import { FiX } from "react-icons/fi";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "../components/-common";
import AuthRoutes from "../components/@Auth";
import Children from "../components/@Children";
import Classes from "../components/@Classes";
import Enrollments from "../components/@Enrollments/Enrollments";
import Files from "../components/@Files";
import OrderMaterials from "../components/@OrderMaterials";
import Profile from "../components/@Profile";
import Teachers from "../components/@Teachers";
import ThankYou from "../components/@ThankYou";
import Sidebar from "../components/Sidebar";
import useAmplitudeAnalytics from "../hooks/useAmplitudeAnalytics";
import AuthStore from "../stores/AuthStore";
import UserStore from "../stores/UserStore";
import "./App.scss";

const ToastClose = ({ closeToast }) => (
  <div className="toast-close">
    <FiX size={20} onClick={closeToast} />
  </div>
);

function Main() {
  useAmplitudeAnalytics();
  const { teacher: isTeacher, parent: isParent } = UserStore?.user || {};

  return (
    <div className="main-content">
      <Sidebar />
      <Routes>
        <Route path="/profile" element={<Profile />} />

        {isTeacher && (
          <>
            <Route path="/files/*" element={<Files />} />
            <Route path="/classes/*" element={<Classes />} />
          </>
        )}

        {isParent && (
          <>
            <Route path="/children/*" element={<Children />} />
            <Route path="/enrollments" element={<Enrollments />} />
            <Route path="/teachers/*" element={<Teachers />} />
          </>
        )}

        <Route path="/materials" element={<OrderMaterials />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="*" element={<Navigate to="/profile" replace />} />
      </Routes>
    </div>
  );
}

const App = observer(() => {
  if (!AuthStore.authDetermined || (AuthStore.authenticated && !UserStore?.user?.id && UserStore.loading)) {
    return (
      <div className="root loading">
        <Spinner alt={true} />
      </div>
    );
  }

  if (AuthStore.authenticated) return <Main />;
  return <AuthRoutes />;
});

function ProvidersWrappedApp() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeButton={<ToastClose />}
        className="lpm-toast"
      />
      <App />
    </BrowserRouter>
  );
}

export default ProvidersWrappedApp;
