import { makeAutoObservable } from "mobx";
import { 
  signIn, 
  signOut, 
  confirmSignUp, 
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes
} from '@aws-amplify/auth';
import { request } from "../utils";
import { resetAmplitude, setAmplitudeUserId, trackAmplitudeEvent } from "../services/AmplitudeService";
import { EVENT_NAMES } from "../constants";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    // Delay the auth check to ensure Amplify is initialized
    setTimeout(() => this.checkForAuthenticatedUser(), 200);
  }

  authDetermined = false;
  authenticated = false;
  user = {};
  groups = [];
  loading = false;

  loginAnalyticsEvents() {
    setAmplitudeUserId(this.id);
    trackAmplitudeEvent(EVENT_NAMES.LOGGED_IN);
  }

  get sub() {
    return this.user?.attributes?.sub;
  }

  get id() {
    return this.user?.attributes?.["custom:lpmId"];
  }

  get infusionsoftId() {
    return this.user?.attributes?.["custom:infusionsoftId"];
  }

  get email() {
    return this.user?.attributes?.["email"];
  }

  async checkForAuthenticatedUser() {
    try {
      // Check if user is authenticated
      const user = await getCurrentUser();
      if (user) {
        // Also fetch the session to get tokens and groups
        const session = await fetchAuthSession();
        const { "cognito:groups": groups } = session?.tokens?.accessToken?.payload || {};
        
        // Get user attributes
        const attributes = await fetchUserAttributes();
        this.user = { ...user, attributes };
        this.groups = groups || [];
        this.authenticated = true;
        this.loginAnalyticsEvents();
      } else {
        this.authenticated = false;
        this.user = {};
      }
    } catch (err) {
      // This also means user isn't authenticated
      console.log('Not authenticated', err);
      this.authenticated = false;
    }

    this.authDetermined = true;
  }

  async signIn(username, password) {
    this.loading = true;
    try {
      // First check if already signed in
      let isAlreadySignedIn = false;
      try {
        await getCurrentUser();
        isAlreadySignedIn = true;
      } catch (e) {
        // Not signed in, which is what we want
      }
      
      // If already signed in, sign out first
      if (isAlreadySignedIn) {
        try {
          console.log("User already signed in, signing out first...");
          await signOut({ global: true });
          // Wait a moment for the sign-out to complete
          await new Promise(resolve => setTimeout(resolve, 500));
        } catch (e) {
          console.error("Error during sign-out:", e);
        }
      }
      
      // Now try to sign in
      const { isSignedIn } = await signIn({
        username: username || "-",
        password: password || "-",
      });
      
      if (isSignedIn) {
        let user = await getCurrentUser();
        let attributes = await fetchUserAttributes();
        let session = await fetchAuthSession();
        const { "cognito:groups": groups } = session?.tokens?.accessToken?.payload || {};

        if (!attributes?.["custom:lpmId"]) {
          try {
            await request.post("/users/me/sync");
            // Re-authenticate to get updated attributes
            await signIn({
              username: username || "-",
              password: password || "-",
            });
            user = await getCurrentUser();
            attributes = await fetchUserAttributes();
            session = await fetchAuthSession();
          } catch (syncError) {
            console.warn("Error syncing user:", syncError);
          }
        }

        this.user = { ...user, attributes };
        this.groups = groups || [];
        this.authenticated = true;
        this.loginAnalyticsEvents();
        this.loading = false;
        return { success: true };
      }
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
      this.loading = false;
      return { success: false, error: err.message };
    }
    
    this.loading = false;
    return { success: false, error: "Failed to sign in" };
  }

  async signUp({ email, password, firstName, lastName, isConsultant }) {
    this.loading = true;

    let success, error;
    try {
      await signIn({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            "custom:firstName": firstName,
            "custom:lastName": lastName,
            "custom:accountType": isConsultant ? "consultant" : "company"
          }
        }
      });
      success = true;
    } catch (err) {
      console.warn(err);
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async resendVerificationCode(email) {
    let success, error;

    this.loading = true;
    try {
      await signIn({ username: email });
      success = true;
    } catch (err) {
      error = err.message;
      success = false;
    }

    this.loading = false;
    return { success, error };
  }

  async verifyAccount(username, password, code) {
    this.loading = true;
    try {
      await confirmSignUp({ username, confirmationCode: code });
      await this.signIn(username, password);
      return { success: true };
    } catch (err) {
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async sendResetPasswordCode(email) {
    let success, error;

    this.loading = true;
    try {
      await resetPassword({ username: email });
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async resetPassword(username, code, newPassword) {
    this.loading = true;
    let success, error;

    try {
      await confirmResetPassword({ 
        username, 
        confirmationCode: code, 
        newPassword 
      });
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async signOut() {
    try {
      await signOut({ global: true });
      this.user = {};
      this.authenticated = false;
      trackAmplitudeEvent(EVENT_NAMES.LOGGED_OUT);
      resetAmplitude();
    } catch (err) {
      console.warn(err);
    }
  }
}

export default new AuthStore();
